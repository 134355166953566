.navbar {
  a {
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
  }
  z-index: 5;
  #navbarSupportedContent {
    .nav-item {
      font-family: 'Avenir-Light';
      text-transform: uppercase;
      &.active {
        font-family: 'Avenir-Medium'
      }
    }
  }
}
