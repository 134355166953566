@import './fonts/fonts.scss';

// $body-bg: #ffffff;
// $body-color: #343a40;

// $body-bd-light: red;

// $theme-colors: (
//   'primary': #e26926,
// );

$colors: (
  'red': #470006,
  'light-red': #DF5656,
  'black': #000000,
  'green': #9BC44C,
  'blue': #A7C1E2,
  'light-blue': #E3EBF6,
  'dark-blue': #004A91,
  'yellow': #FAE095,
  'light-yellow': #FCEAB8,
  'lighter-yellow': #FDF6DE
);


$font-family-sans-serif: 'Roboto Condensed' !default;
// $font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base:       $font-family-sans-serif !default;

// // Overriding custom bootstrap colors
// $green: map-get($colors, 'green');

// Our colors
@each $color, $hex in $colors {
  .bg-#{$color} { background-color: $hex; }
  .text-#{$color} { color: $hex; }
  .border-#{$color} { border: $hex solid;  }
}

// blockquote {
//   background-color: map-get($colors, 'light-blue');
//   .blockquote-footer {
//     font-size: .7em;
//     position: absolute;
//     bottom: 25px;
//   }
// }

// ul.list {
//   list-style-type: none;
//   li::before {
//     content: '\25A0';
//     color: map-get($colors, 'green');
//     display: inline-block;
//     font-weight: bold;
//     width: 1em;
//     margin-left: -1em;
//   }
// }

// .asterisk {
//   color: map_get($theme-colors, 'primary');
//   margin-right: 0.25em;
// }

@import '~bootstrap/scss/bootstrap.scss';