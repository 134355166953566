html {
  scroll-behavior: smooth;
}

.app {
  text-align: center;
}

.app-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 { font-size: 4em; }

.vl {
  border-left: 1px solid #dcddde;
 }
