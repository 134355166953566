#header {
  position: relative;
  height: 100vh;
  background: url('/background.jpg') no-repeat center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
  }
  // .title {
  //   position: absolute;
  //   bottom: 10vh;
  //   left: 30vh;
  // }

  // .icon {
  //   position: absolute;
  //   bottom: 10vh;
  //   right: 30vh;
  // }
}